import React from "react";
import { Form as FinalFormForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FormApi, SubmissionErrors, ValidationErrors } from "final-form";

type FormProps = Omit<
  React.FormHTMLAttributes<HTMLFormElement>,
  | "onSubmit"
  | "onBlur"
  | "onChange"
  | "onFocus"
  | "value"
  | "checked"
  | "multiple"
>;

type OnSubmitFn<FormValues> = (
  values: FormValues,
  form: FormApi<FormValues>,
  callback?: (errors?: SubmissionErrors) => void,
) => SubmissionErrors | Promise<SubmissionErrors> | void;

type ValidateFn<FormValues> = (
  values: FormValues,
) => ValidationErrors | Promise<ValidationErrors>;

type Props<FormValues> = {
  onSubmit: OnSubmitFn<FormValues>;
  initialValues?: Partial<FormValues>;
  validate?: ValidateFn<FormValues>;
} & FormProps;

function Form<FormValues = Record<string, any>>(props: Props<FormValues>) {
  const { onSubmit, validate, initialValues, children, ...otherProps } = props;

  return (
    <FinalFormForm<FormValues>
      validateOnBlur
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
    >
      {(ffProps) => (
        <form onSubmit={ffProps.handleSubmit} {...otherProps}>
          {children}
        </form>
      )}
    </FinalFormForm>
  );
}

export default Form;
