import React from "react";
import { useFormState } from "react-final-form";
import { Button } from "../Button";

type Props = React.ComponentProps<typeof Button>;

const Submit = (props: Props) => {
  const { children, ...otherProps } = props;
  const s = useFormState({
    subscription: { submitting: true, invalid: true },
  });

  const { submitting } = s;

  return (
    <Button
      type="submit"
      loading={submitting}
      disabled={submitting}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default Submit;
