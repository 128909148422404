import React from "react";
import classnames from "classnames";
import { useField } from "react-final-form";

const baseClass = `
  disabled:bg-gray-200
  disabled:cursor-not-allowed
  mt-1.5
  block
  w-full
  rounded-md
  border-gray-300
  focus:border-blue-300
  focus:ring
  focus:ring-blue-200
  focus:ring-opacity-50
`
  .split(/\s+/)
  .join(" ");

const errorClass = "ring-2 ring-offset-2 ring-red-600";

type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onBlur" | "onChange" | "onFocus" | "value" | "checked" | "multiple"
>;

const TextField = (props: InputProps) => {
  const {
    name,
    type = "text",
    className: userClassName,
    ...otherProps
  } = props;

  const { input, meta } = useField(name);
  const showError = meta.submitError && !meta.modifiedSinceLastSubmit;
  const className = classnames(
    userClassName,
    baseClass,
    showError && errorClass,
  );

  return (
    <input
      name={name}
      type={type}
      {...otherProps}
      disabled={props.disabled || meta.submitting}
      className={className}
      {...input}
    />
  );
};

export default TextField;
