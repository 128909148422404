import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SelectField from "./SelectField";
import type { Props as SelectFieldProps } from "./SelectField";
import useFieldValue from "./useFieldValue";

type CityFieldProps = {
  stateFieldName?: string;
} & Omit<SelectFieldProps, "options">;

/**
 * CityField will show a list of cities available based on the selected state
 */
const CityField = (props: CityFieldProps) => {
  const stateFieldName = props.stateFieldName || "state";
  const state = useFieldValue(stateFieldName);

  const { allStrapiCity } = useStaticQuery(graphql`
    query {
      allStrapiCity(sort: { fields: [name], order: ASC }) {
        nodes {
          name
          slug
          state {
            slug
          }
        }
      }
    }
  `);

  const options = allStrapiCity.nodes
    .filter((n) => {
      return n.state.slug === state;
    })
    .map((n) => {
      return { value: n.slug, label: n.name };
    });

  if (state) {
    options.push({
      value: "other",
      label: "Otra ciudad",
    });
  }

  return <SelectField options={options} {...props} />;
};

export default CityField;
