import React from "react";
import { useFormState } from "react-final-form";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { FORM_ERROR } from "final-form";

type AlertProps = {
  submitError: string[];
  errors: string[];
};

const Alert = ({ submitError, errors }: AlertProps) => {
  return (
    <div className="bg-yellow-50 border-l-4 border-yellow-400 my-4 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          {submitError && (
            <span className="font-bold text-sm text-yellow-700">
              {submitError}
            </span>
          )}
          <ul className="list-disc list-inside">
            {errors.map((text, i) => (
              <li key={i} className="text-sm text-yellow-700">
                {text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const SubmitError = () => {
  const s = useFormState({
    subscription: {
      submitErrors: true,
      submitError: true,
      submitFailed: true,
      modifiedSinceLastSubmit: true,
      errors: true,
    },
  });

  const {
    modifiedSinceLastSubmit,
    submitError,
    submitErrors,
    errors,
    submitFailed,
  } = s;
  if (!submitFailed) {
    return null;
  }

  if (modifiedSinceLastSubmit) {
    return null;
  }

  const errorToUse = submitErrors ?? errors;
  const errorList = Object.keys(errorToUse)
    .filter((k) => k !== FORM_ERROR)
    .map((k) => errorToUse[k]);

  return <Alert submitError={submitError} errors={errorList} />;
};

export default SubmitError;
