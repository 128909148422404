import React from "react";
import classnames from "classnames";
import { useField } from "react-final-form";

type SelectProps = Omit<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  "onBlur" | "onChange" | "onFocus" | "value" | "checked" | "multiple"
>;

type Option = {
  value: string;
  label: string;
};

export type Props = {
  options: Option[];
} & SelectProps;

export type InputProps = {
  input: any;
  meta: any;
  options: Option[];
} & SelectProps;

const errorClass = "text-red-900 ring-2 ring-offset-2 ring-red-600";

const baseClass =
  "block w-full mt-1.5 rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50";

const SelectInput = (props: InputProps) => {
  const {
    name,
    options,
    input,
    meta,
    className: userClassName,
    ...otherProps
  } = props;

  const showError = meta.submitError && !meta.modifiedSinceLastSubmit;
  const className = classnames(
    userClassName,
    baseClass,
    showError && errorClass,
  );

  return (
    <select
      defaultValue=""
      name={name}
      {...otherProps}
      disabled={props.disabled || meta.submitting}
      className={className}
      {...input}
    >
      <option selected disabled value="">
        Selecciona una opción
      </option>
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  );
};

const SelectField = (props: Props) => {
  const { name } = props;
  const { input, meta } = useField(name);
  return <SelectInput {...props} input={input} meta={meta} />;
};

SelectField.Input = SelectInput;

export default SelectField;
