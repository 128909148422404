import React, { useCallback } from "react";
import { graphql, useStaticQuery } from "gatsby";
import SelectField from "./SelectField";
import type { Props as SelectFieldProps } from "./SelectField";
import { useField, useForm } from "react-final-form";

type StateFieldProps = Omit<SelectFieldProps, "options">;

const StateField = (props: StateFieldProps) => {
  const { allStrapiState } = useStaticQuery(graphql`
    query {
      allStrapiState(sort: { fields: [name], order: ASC }) {
        nodes {
          name
          slug
        }
      }
    }
  `);

  const form = useForm();
  const { input, meta } = useField(props.name);

  const inputOnChange = input.onChange;

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      form.batch(() => {
        inputOnChange(e);
        // Set the city to blank if the state changes
        form.change("city", "");
      });
    },
    [form, inputOnChange],
  );

  const newInput = {
    ...input,
    onChange,
  };

  const options = allStrapiState.nodes.map((n) => {
    return { value: n.slug, label: n.name };
  });

  return (
    <SelectField.Input
      input={newInput}
      meta={meta}
      options={options}
      {...props}
    />
  );
};

export default StateField;
