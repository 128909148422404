import React, { useCallback } from "react";
import _ from "lodash";
import {
  WebleadPreviewResponse,
  finalForm,
  webleadPreview,
} from "../common/api";
import { capture, useBrightFeatureFlag } from "../common/analytics";

import Form from "./form/Form";
import Label from "./form/Label";
import TextField from "./form/TextField";
import SubmitError from "./form/SubmitError";
import Submit from "./form/Submit";
import StateField from "./form/StateField";
import CityField from "./form/CityField";
import { useUiFriendlyWebleadPreviewParams } from "./pricerFormUtils";

export type customerType = "commercial" | "residential" | "industrial";
export const customerTypes = ["commercial", "residential", "industrial"];

export type PricerFormValues = {
  city: string;
  state: string;
  lastBillMxn: string;
  tarifa: string;
  postalCode: string;
};

type Props = {
  customerType: customerType;
  city?: string;
  state?: string;
  lastBillMxn?: string;
  onSuccess: (
    result: WebleadPreviewResponse,
    input: PricerFormValues,
  ) => Promise<any>;
};

const PricerForm = ({
  customerType,
  city,
  lastBillMxn,
  state,
  onSuccess,
}: Props) => {
  const variant = useBrightFeatureFlag("interactive_proposal_7");
  const doApiCall = useUiFriendlyWebleadPreviewParams();

  const initialValues = { city, state, lastBillMxn };
  const onSubmit = useCallback(
    async (formValues: PricerFormValues) => {
      const { lastBillMxn, city, state } = formValues;
      const apiPayload = doApiCall({
        lastBillMxn,
        city,
        variant,
        customerType,
        riskCategory: "high_risk",
      });
      const { tarifa, postalCode } = apiPayload;

      let errors: Record<string, string> = {};
      if (!lastBillMxn || !lastBillMxn.match(/[\d]{2,8}/)) {
        errors.lastBillMxn = "Para importe de CFE escribe sólo los numeros. ";
      }

      if (!state) {
        errors.state = "Por favor seleccione un estado";
      } else if (!city) {
        errors.city = "Por favor seleccione una ciudad";
      } else if (!tarifa || !postalCode) {
        errors.city = "El estimador de costos no funciona para esta ciudad";
      }

      if (Object.keys(errors).length) {
        return errors;
      }

      const payload = {
        lastBillMxn,
        city,
        customerType,
        variant,
      } as const;

      const finalFormSuccess = async (body: WebleadPreviewResponse) => {
        capture("estimate-viewed", { payload, response: body });
        await onSuccess(body, { city, state, tarifa, postalCode, lastBillMxn });
      };

      return finalForm(finalFormSuccess, webleadPreview(apiPayload));
    },
    [doApiCall, variant, customerType],
  );

  return (
    <Form<PricerFormValues> onSubmit={onSubmit} initialValues={initialValues}>
      <Label>Estado</Label>
      <StateField name="state" />

      <Label>Ciudad</Label>
      <CityField name="city" />

      <Label>Escribe cuanto pagas de luz cada bimestre</Label>
      <TextField
        name="lastBillMxn"
        placeholder="5000"
        pattern="[0-9]{2,8}"
        title="Escribe sólo los numeros"
      />

      <SubmitError />
      <div className="flex flex-col items-stretch mt-4">
        <Submit>Enviar</Submit>
      </div>
    </Form>
  );
};

export default PricerForm;
