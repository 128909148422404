import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Container from "./Container";

const SectionHeader = ({ children }) => {
  return <h2 className="text-3xl font-bold">{children}</h2>;
};

const SectionDescription = ({ children }) => {
  return <p className="text-lg font-base mt-4 text-gray-500">{children}</p>;
};

const WelcomeComfort = () => {
  return (
    <div className="bg-gray-100 py-16 self-stretch">
      <Container column>
        <SectionHeader>
          Dale la bienvenida a la comodidad en tu vida
        </SectionHeader>
        <SectionDescription>
          Además de ayudar al medio ambiente y reducir los alto indices de
          contaminación, instalar un sistema solar en tu hogar te ayudará a
          olvidarte de los altos incrementos en las tarifas y de tu recibo de
          luz.
        </SectionDescription>

        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 gap-10 md:grid-cols-3 justify-center items-center rounded-3xl my-6 p-4 max-w-5xl">
            <div className="flex flex-col shadow-md rounded-md bg-white h-full p-5">
              <div className="pb-5">
                <StaticImage
                  alt="icono minisplit"
                  height={100}
                  src="../../images/illustrations/minisplit-icon.png"
                />
              </div>
              <div className="font-bold">Libertad de consumo</div>
              <div>
                Utiliza tus aparatos electrónicos sin miedo, incluyendo tu
                minisplit.
              </div>
            </div>
            <div className="flex flex-col shadow-md rounded-md bg-white h-full p-5">
              <div className="pb-5">
                <StaticImage
                  alt="icono hombre sentado sillon"
                  height={100}
                  src="../../images/illustrations/comfort-icon.png"
                />
              </div>
              <div className="font-bold">Tranquilidad y comodidad</div>
              <div>Siempre sabrás cuánto vas a pagar de electricidad.</div>
            </div>
            <div className="flex flex-col shadow-md rounded-md bg-white h-full p-5">
              <div className="pb-5">
                <StaticImage
                  alt="icono recibo de luz congelado"
                  height={100}
                  src="../../images/illustrations/frozen-bill-icon.png"
                />
              </div>
              <div className="font-bold">Evita consumir el nivel excedente</div>
              <div>
                Congela tu tarifa eléctrica y mantén el subsidio de CFE para no
                llegar a tarifa DAC.
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default WelcomeComfort;
