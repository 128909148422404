import { useField } from "react-final-form";

const useFieldValue = (field: string) => {
  const {
    input: { value },
  } = useField(field, {
    subscription: {
      value: true,
    },
  });

  return value;
};

export default useFieldValue;
