import _ from "lodash";
import React, { useState } from "react";
import { capture } from "../common/analytics";
import { WebleadPreviewResponse } from "../common/api";
import { apiRequestToOps } from "../common/api";
import { Button } from "./Button";

export type PricerFormValues = {
  city: string;
  state: string;
  lastBillMxn: string;
  tarifa: string;
  postalCode: string;
};

const normalizePhone = (phone) => {
  if (!phone) {
    return "";
  }

  // Remove whitespace and dashes
  return phone.replace(/\s+/g, "").replace(/-/g, "");
};

const submitFormToApi = async (payload: any) => {
  let url: string;
  if (process.env.NODE_ENV === "production") {
    url = "https://api.thinkbright.mx/leads/reserveOnlineInstallation";
  } else {
    url = "http://localhost:8089/leads/reserveOnlineInstallation";
  }

  await apiRequestToOps("POST", url, payload);
};

type Props = {
  previewResp: WebleadPreviewResponse;
  closeModal: () => void;
};

const ScheduleInstallationForm = ({ previewResp, closeModal }: Props) => {
  const [formFields, setFormFields] = useState<any>({
    fields: {
      agreeToContact: true,
      phone: sessionStorage.getItem("phone"),
      token: sessionStorage.getItem("token"),
    },
    errors: {},
  });
  const [loading, setLoading] = useState(false);
  const [apiCallExecuted, setApiCallExecuted] = useState(false);

  const handleFormChange = (field, value) => {
    let fields = { ...formFields.fields };
    fields[field] = value;
    setFormFields({ fields });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formInputValidation()) {
      capture("reserve_online_installation_form_submit_invalid");
      return;
    }

    const { phone } = formFields.fields;

    const payload = {
      ...formFields.fields,
      phone: `+52${normalizePhone(phone)}`,
    };
    try {
      setLoading(true);
      await submitFormToApi(payload);
      setApiCallExecuted(true);

      capture("reserve_online_installation_form_submit", {
        phone: payload.phone,
      });

      setTimeout(() => closeModal(), 5000);
    } catch (err) {
      capture("reserve_online_installation_form_submit_failed", {
        phone: payload.phone,
      });
      setLoading(false);
      console.error(err);
    }
  };

  const formInputValidation = () => {
    let errors = {};
    let formIsValid = true;
    let fields = formFields.fields;
    let phone = fields.phone;

    // Phone
    if (!phone) {
      formIsValid = false;
      errors["phone"] = "Ingresa un teléfono de contacto";
    }

    if (typeof phone !== "undefined") {
      if (!normalizePhone(phone).match(/^[0-9]*$/)) {
        formIsValid = false;
        errors["phone"] = "Ingresa solo números";
      }
    }

    if (phone && normalizePhone(phone).length !== 10) {
      formIsValid = false;
      errors["phone"] = "Ingresa un teléfono de 10 dígitos";
    }

    // agreeToContact
    if (!fields.agreeToContact) {
      formIsValid = false;
      errors["agreeToContact"] = "Es necesario que aceptes";
    }

    if (!formIsValid) {
      setFormFields({ errors: errors, fields: fields });
    }

    return formIsValid;
  };

  const { systemSizeWatts, numPanels } = previewResp;
  const systemSizeKW = (systemSizeWatts / 1000.0).toFixed(2);
  const { monthlyFee, annualEscalator } = previewResp.operating;
  const escalator = ((annualEscalator - 1.0) * 100).toFixed(2);
  const panelWatts = systemSizeWatts / numPanels;
  const summaryData = {
    systemSize: `${systemSizeKW} kW`,
    panelCount: `${numPanels}`,
    panelType: `Paneles Premium de ${panelWatts}W`,
    monthlyPayment: `$${monthlyFee}`,
    initialDeposit: "$0",
    escalator: `${escalator}% anual`,
  };

  return (
    <div className="max-w-md mx-auto bg-white p-4 rounded-md">
      <h1 className="text-xl font-bold text-center">
        ¡Felicidades! Estás un paso más cerca de disfrutar los beneficios del
        Sol.
      </h1>

      <div className="py-8 space-y-4">
        <div className="border-b-2 pb-4 border-t-2 pt-4 grid grid-cols-2 gap-4 py-4">
          <p className="col-span-2 text-2xl font-semibold">Resumen</p>
          <p className="font-medium">Tamaño del sistema</p>
          <p>{summaryData.systemSize}</p>

          <p className="font-medium">Cantidad de paneles</p>
          <p>{summaryData.panelCount}</p>

          <p className="font-medium">Tipo de panel</p>
          <p>{summaryData.panelType}</p>
          {/* disabling this to stop hurting conversation due the recent changes in prices
           <p className="font-medium">Mensualidad</p>
          <p>{summaryData.monthlyPayment}</p>

          <p className="font-medium">Depósito Inicial</p>
          <p>{summaryData.initialDeposit}</p>

          <p className="font-medium">Escalador</p>
          <p>{summaryData.escalator}</p> */}
        </div>

        <p className="text-medium text-center">
          Para continuar con tu proceso y resolver todas tus dudas, te
          contactaremos por WhatsApp. <br />
          <br />
          Nunca compartiremos tu número, es solo para ponernos en contacto de la
          manera más eficiente posible. Si después de conversar con nosotros, no
          te interesa, no volveremos a contactarte.
        </p>
      </div>

      {!apiCallExecuted ? (
        <form onSubmit={handleSubmit}>
          {/* Phone Number */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              Confirma tu número de teléfono
            </label>
            <input
              type="tel"
              id="phone"
              value={formFields.fields["phone"]}
              onChange={(e) => handleFormChange("phone", e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md"
              placeholder="55 5555 5555"
            />
            {_.has(formFields, "errors.phone") && (
              <p className="mt-2 text-sm text-red-600" id="phone-error">
                {formFields.errors.phone}
              </p>
            )}
          </div>

          {/* Checkbox */}
          <div className="mt-4">
            <div className="flex items-center">
              <input
                id="agree"
                name="agree"
                type="checkbox"
                checked={formFields.fields["agreeToContact"]}
                onChange={(e) =>
                  handleFormChange("agreeToContact", e.target.checked)
                }
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-900">
                Acepto que me contacten vía WhatsApp
              </label>
            </div>
            {_.has(formFields, "errors.agreeToContact") && (
              <p
                className="mt-2 text-sm text-red-600"
                id="agreeToContact-error"
              >
                {formFields.errors.agreeToContact}
              </p>
            )}
          </div>

          {/* Submit button */}
          <div className="w-auto flex items-center justify-center pt-8">
            <Button type="submit" loading={loading}>
              Continuar
            </Button>
          </div>
        </form>
      ) : (
        <h1 className="text-xl font-bold text-center">
          ¡Gracias! Te enviaremos un mensaje por whatsapp para reservar tu
          instalación.
        </h1>
      )}
    </div>
  );
};

export default ScheduleInstallationForm;
