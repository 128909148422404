import React from "react";

type Props = {
  children: React.ReactNode;
};

const Label = ({ children }: Props) => {
  return (
    <label className="mt-2 block text-base text-gray-700">{children}</label>
  );
};

export default Label;
